
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import { setBreadcrumb } from "@/utils/breadcrumb";
import { defineComponent, onMounted } from "vue";
import CatListMain from "@/features/cat-list/main.vue";

export default defineComponent({
  components: {
    CatListMain,
  },
  setup() {
    // START === setup breadcrumb =====================================================
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "CAT CPNS",
        to: `${dashboardPath}/cat-cpns`,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =======================================================

    return {};
  },
});
